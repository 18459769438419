<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Grabaciones</v-toolbar-title>
    </v-toolbar>
    <!-- <v-banner single-line :sticky="sticky"> Lista de grabaciones </v-banner> -->
    <v-card-text class="white lighten-4">
      <v-row no-gutters>
        <!-- <div class="d-flex align-baseline flex-wrap">
          <span style="max-width: 100px; width: 100%">Buscar:</span>
          <v-text-field
            v-model="searchRecorder"
            class="p-0"
            style="padding: 0; margin-left: 2rem"
          ></v-text-field>
        </div> -->
        <!-- <v-col cols="6" md="4">
          <div class="d-flex align-baseline flex-wrap">
            <v-select
              v-model="year"
              :items="[2021, 2022, 2023, 2024, 2025]"
              label="Año"
              item-text="label"
              item-value="id"
              style="padding: 0; margin-left: 2rem"
              autocomplete
            />
          </div>
        </v-col> -->
        <v-col cols="6" md="4">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
            class="inputDK"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Selecciona una fecha"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <!-- <v-col cols="6" md="4">
          <div class="d-flex align-baseline flex-wrap">
            <v-select
              v-model="month"
              :items="[
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '07',
                '08',
                '09',
                '10',
                '11',
                '12',
              ]"
              label="Mes"
              item-text="label"
              item-value="id"
              style="padding: 0; margin-left: 2rem"
              autocomplete
            />
          </div>
        </v-col>
        <v-col cols="6" md="4">
          <div class="d-flex align-baseline flex-wrap">
            <v-select
              v-model="day"
              :items="daysMonth"
              label="Día"
              style="padding: 0; margin-left: 2rem"
              autocomplete
            />
          </div>
        </v-col>
        </v-col> -->
        <v-col cols="6" md="4">
          <div class="d-flex align-baseline flex-wrap">
            <v-text-field
              v-model="searchTurn"
              label="Turno:"
              class="p-0"
              style="padding: 0; margin-left: 2rem"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="6" md="4">
          <!-- <div class="d-flex align-baseline flex-wrap"> -->
          <!-- <v-text-field
              v-model="searchAsesor"
              label="Asesor (dni):"
              class="p-0"
              style="padding: 0; margin-left: 2rem"
            ></v-text-field> -->

          <!-- </div> -->
          <vi-select
            v-model="searchAsesor"
            :options="advisors"
            placeholder="Asesor"
            label="name"
            item-text="name"
            item-value="id"
            style="padding: 0; margin-left: 2rem"
            autocomplete
          >
            <span slot="no-options">No hay resultados.</span>
          </vi-select>
        </v-col>
        <v-col cols="6" md="4">
          <vi-select
            v-model="searchWorkStation"
            :options="branchesInfoCompanySort"
            placeholder="Puesto de trabajo"
            label="name"
            item-text="name"
            item-value="id"
            style="padding: 0; margin-left: 2rem"
            autocomplete
          >
            <span slot="no-options">No hay resultados.</span>
          </vi-select>
        </v-col>
        <v-col cols="6" md="4">
          <div class="d-flex align-baseline flex-wrap">
            <v-text-field
              v-model="searchClient"
              label="Usuario (dni):"
              class="p-0"
              style="padding: 0; margin-left: 2rem"
            ></v-text-field>
          </div>
        </v-col>
        <!-- <v-col cols="6" md="4">
          <div class="d-flex align-baseline flex-wrap">
            <v-text-field
              v-model="searchInitDate"
              label="Fecha inicial:"
              class="p-0"
              style="padding: 0; margin-left: 2rem"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="6" md="4">
            <v-text-field
              v-model="searchEndDate"
              label="Fecha final:"
              class="p-0"
              style="padding: 0; margin-left: 2rem"
            ></v-text-field>
        </v-col> -->
        <!-- <v-col cols="6" md="4">
            <v-select v-model="searchSucursal" :items="branches.branches" label="Sucursal:" item-text="label" item-value="id" style="padding: 0; margin-left: 2rem" autocomplete/>
        </v-col> -->
        <v-col cols="6" md="4">
          <v-btn
            color="primary"
            style="padding: 0; margin-left: 2rem; width: 108px"
            @click="search"
          >
            Buscar
          </v-btn>

          <v-btn
            color="gray"
            style="padding: 0; margin-left: 2rem; width: 108px"
            @click="clear"
          >
            Limpiar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <!-- Video Modal -->
    <div class="ModalOverlay" v-if="showVideo" @click="closeModalVideo"></div>
    <div v-if="showVideo" max-width="900px" class="Modal">
      <div class="VideoDK">
        <v-expand-transition>
          <v-card-text class="grey lighten-4" v-show="src">
            <div
              v-for="(down, k) in downloads" :key="k"
              :style="down.name.includes(nameSelected) ? 'color:red' : ''"
            >
              {{down.name.split('-')[6].replace(`${down.name.split('-')[6].split('_')[0]}_`, 'Parte ')}}
              <a @click="changevideo(down)">Ver</a>
            </div>
            <v-sheet max-width="890" height="498" class="mx-auto">
              <video
                v-if="src"
                width="100%"
                height="100%"
                autoplay
                id="screen-view"
                controls
              >
                <source :src="src" type="video/mp4" />
              </video>
              <v-btn fab small dark class="button-dk" @click="closeModalVideo"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-sheet>
          </v-card-text>
        </v-expand-transition>
      </div>
    </div>
    <!-- Download Options Modal -->
    <div class="ModalOverlay" v-if="showDownloadVideos" @click="closeModalDownloads"></div>
    <div v-if="showDownloadVideos" max-width="900px" class="Modal" style="width: 26%;">
      <div class="VideoDK">
          <div class="grey lighten-4">
            <div style="height: 384px; padding-top: 16%; text-align: center;">
              <div class="text-h5 mb-1">{{nameSelected.split("-")[1]}}</div>
              <div
                
                v-for="(down, k) in downloads" :key="k"
              >
                {{down.name.split('-')[6].replace(`${down.name.split('-')[6].split('_')[0]}_`, 'Parte ')}}
                <a @click="download(down)">Descargar</a>
              </div>
            </div>
          </div>
      </div>
    </div>
    <!-- progress -->
    <v-progress-linear
      v-if="loading"
      indeterminate
      value="15"
    ></v-progress-linear>
    <v-list three-line v-if="filterItems.length" style="margin: 30px">
      <v-row>
        <v-col
          v-show="index + 1 >= init && index + 1 <= limit"
          v-for="(item, index) in filterItems"
          :key="index"
          :cols="2"
          :class="nameSelected == item.name ? 'active card-dk' : 'card-dk'"
          style="padding: 5px"
        >
          <v-card
            class="mx-auto"
            max-width="274"
            outlined
          >
            <v-list-item three-line @click="selectVideo(item)">
              <v-list-item-content>
                <div class="text-overline mb-4">
                  {{ item.LastModified }}
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ item.name.split("-")[1] }}
                </v-list-item-title>
                <v-list-item-subtitle
                  >Sucursal:
                  {{ getBranch(item.name.split("-")[5].split(".")[0]).label }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  >Puesto: {{ item.name.split("-")[4] }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  >Asesor (dni): {{ asesorName(item.name.split("-")[3]) }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  >Usuario (dni): {{ item.name.split("-")[2] }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  >Fecha del turno:
                  {{ toDate(item.name.split("-")[0].split("videos/")[1]) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <a @click="download(item)">Descargar</a>
          </v-card>
        </v-col>
      </v-row>
    </v-list>
    <p v-if="!loading && filterItems.length < 1" style="padding: 1rem">
      No hay resultados
    </p>
    <div class="text-center" v-show="filterItems.length > 1">
      <v-pagination
        v-model="page"
        :length="Math.ceil(filterItems.length / this.pagination)"
      ></v-pagination>
    </div>
  </v-card>
</template>
<script>
var AWS = require("aws-sdk");
import { mapActions, mapState } from "vuex";
import moment from "moment";
import _ from "lodash";
let s3, bucketName;

export default {
  name: "Recorders",
  data: () => ({
    sticky: false,
    showVideo: false,
    modalInfoVideo: null,
    items: [],
    searchRecorder: "",
    loading: false,
    searchSucursal: "",
    searchWorkStation: "",
    searchEndDate: "",
    searchAsesor: "",
    searchClient: "",
    searchInitDate: "",
    searchTurn: "",
    year: "",
    month: "",
    page: 1,
    src: "",
    day: null,

    filterItems: [],
    nameSelected: "",

    pageOfItems: [],

    pagination: 20,
    initFixed: 1,
    limitFixed: 20,
    init: 1,
    limit: 20,
    days: [],

    // datepicker
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    modal: false,

    // modal download
    showDownloadVideos: false,
    downloads: []

  }),
  computed: {
    ...mapState({
      profileData: (state) => state.profileSilver.profileData,
      branches: (state) => state.branchesSilver.branchesData,
      branchesInfoCompany: (state) => {
        return state.profileSilver.branchesInfoCompany;
      },
      branchesInfoCompanySort: (state) => {
        return state.profileSilver.branchesInfoCompany?.sort();
      },
      // daysMonth() {
      //   let maxDay = new Date(this.year, this.month, 0).getDate();
      //   return [...Array(maxDay).keys()].map((i) => {
      //     let day = i + 1;
      //     day = day > 9 ? day.toString() : "0" + day;
      //     return day;
      //   });
      // },
      selectedBranch: (state) => state.branchesSilver.selectedBranch,
      advisors: (state) => {
        let users = state.loggedUserSilver.users;
        return users.map((u) => {
          return {
            name: `${u.uUser.firstName} ${u.uUser.lastName}`,
            id: u.id,
          };
        });
      },
    }),
    // filterItems() {
    //   this.searchRecorder;
    //   const filteredRenponse = this.items.filter((item) => {
    //     return item.name
    //       .toLowerCase()
    //       .includes(this.searchRecorder.toLowerCase());
    //   });
    //   return filteredRenponse;
    // },
  },
  watch: {
    page: function (val) {
      this.limit = this.limitFixed * val;
      this.init = this.limit - this.limitFixed;
    },
  },
  created() {
    // var currentTime = new Date();
    // this.year = currentTime.getFullYear();
    // let month = currentTime.getMonth() + 1;
    // this.month = month > 9 ? month : "0" + month;

    // enable change work station
    this.$root.enableChangeWorkStation = true;
    this.checkToken();
    this.handleUsers();
  },
  methods: {
    ...mapActions(["checkToken", "handleUsers"]),
    selectVideo(item) {
      const url = this.signObject(item);
      this.src = url;
      this.showVideo = true;
      this.modalInfoVideo = item;
      this.nameSelected = item.name;
      if(item.links && item.links.length > 1){
        this.downloads = item.links;
      }
    },
    changevideo(item){
      this.src = null;
      this.nameSelected = item.name;
      setTimeout(() => {
        const url = this.signObject(item);
        this.src = url;
      }, 1000);
    },
    closeModalVideo() {
      this.modalInfoVideo = null;
      this.showVideo = false;
      this.src = "";
      this.downloads = [];
    },
    closeModalDownloads() {
      this.showDownloadVideos = false;
      this.downloads = [];
    },
    toDate(unix_timestamp) {
      if (unix_timestamp.length > 12) {
        return moment
          .unix(unix_timestamp.slice(0, -3))
          .format("YYYY/MM/DD hh:mm A");
      } else {
        return moment.unix(unix_timestamp).format("YYYY/MM/DD hh:mm A");
      }
    },
    getBranch(i) {
      if (this.branches) {
        let b = this.branches.branches.filter((b) => b.id == i);
        return b.length > 0 ? b[0] : {};
      } else return {};
    },
    getWorkStation(i) {
      if (this.branches) {
        let ws = this.branchesInfoCompany.filter((w) => w.id == i);
        return ws.length > 0 ? ws[0] : {};
      } else return {};
    },
    async search() {
      this.loading = true;
      this.src = "";
      this.year = moment(this.date).format("YYYY");
      this.month = moment(this.date).format("MM");
      this.day = moment(this.date).format("DD");

      AWS.config.region = "us-east-1"; // 1. The region
      const save_key = process.env.VUE_APP_AWS_POLLING_API_KEY;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: save_key, // 2. The identity pool
      });
      this.searchSucursal =
        this.selectedBranch.id || String(this.branches.defaultBranch.id) || "";

      if (this.profileData.user?.company) {
        AWS.config.credentials.get(function (err) {
          if (err) alert(err);
        });
      }

      s3 = new AWS.S3();
      bucketName = "silver-screen-recorders";

      let prefix =
        process.env.VUE_APP_RECORDER_FOLDER + `/${this.year}/${this.month}/`;
      // let prefix = 'videos/';

      if (this.day) {
        prefix += `${this.day}/`;
      }

      var params = {
        Bucket: bucketName,
        Delimiter: "",
        Prefix: prefix,
      };

      // let vue = this;
      let allKeys = [];

      let videos = await new Promise((resolve) => {
        this.fetchObjects(s3, params, allKeys, resolve);
      });

      this.items = videos.sort(function (a, b) {
        return (
          moment(b.LastModified).toDate() - moment(a.LastModified).toDate()
        );
      });

      // Merge same videos by parts
      let itemCus = this.items
      .reduce((accumulator, curValue)=>{
        let name = curValue.name.split("-")[6];
        name = name.split("_");
        name = name[0];
        let turn = curValue.name.split("-")[1];
        name = name + '_' + turn;
        if(accumulator[name]){
          accumulator[name].push(curValue)
        } else {
          accumulator[name] = [curValue];
        }
        return accumulator;
      }, {});

      itemCus = Object.keys(
        itemCus
      )
      .map( i => {
        return {
          name: itemCus[i][itemCus[i].length-1].name,
          LastModified: itemCus[i][itemCus[i].length-1].LastModified,
          value: itemCus[i][itemCus[i].length-1].value,
          links: _.sortBy(itemCus[i], ['name'])
        }
      })

      this.filterItems = itemCus
        .filter((item) => {
          return item.name
            .split("-")[5] // branch_id in position 5
            .toLowerCase()
            .includes(String(this.searchSucursal || "").toLowerCase());
        })
        .filter((item) => {
          let f = this.searchWorkStation
            ? typeof this.searchWorkStation == "object"
              ? this.searchWorkStation?.id
              : ""
            : "";
          return item.name.toLowerCase().includes(String(f));
        })
        .filter((item) => {
          let a = this.searchAsesor
            ? typeof this.searchAsesor == "object"
              ? this.searchAsesor?.id
              : ""
            : "";
          // return item.name.toLowerCase().includes(String(a));
          if(a && a != '') return item.name.split('-')[3] == a;
          return item.name;
        })
        .filter((item) => {
          return item.name
            .toLowerCase()
            .includes(this.searchTurn.toLowerCase());
        })
        .filter((item) => {
          return item.name
            .toLowerCase()
            .includes(this.searchClient.toLowerCase());
          // .includes(this.searchInitDate.toLowerCase())
        });

      this.loading = false;
      // });
    },

    fetchObjects(s3, params, allKeys, resolve) {
      let vue = this;
      s3.listObjectsV2(params, function (err, data) {
        if (err) {
          console.log(err, err.stack); // an error occurred
        } else {
          var contents = data.Contents;
          var href = this.request.httpRequest.endpoint.href;
          var bucketUrl = href + params.Bucket + "/";
          contents.forEach(function (video) {
            var videoUrl = bucketUrl + encodeURIComponent(video.Key);
            allKeys.push({
              name: video.Key,
              value: videoUrl,
              LastModified: moment(video.LastModified).format(
                "YYYY/MM/DD hh:mm A"
              ),
            });
          });

          if (data.IsTruncated) {
            params.ContinuationToken = data.NextContinuationToken;
            vue.fetchObjects(s3, params, allKeys, resolve);
          } else {
            resolve(allKeys);
          }
        }
      });
    },

    clear() {
      this.items = [];
      this.day = null;
      this.filterItems = [];
      this.searchRecorder = "";
      this.loading = "";
      this.searchSucursal = "";
      this.searchWorkStation = "";
      this.searchEndDate = "";
      this.searchAsesor = "";
      this.searchClient = "";
      this.searchInitDate = "";
      this.searchTurn = "";
      this.src = "";
      let video = document.getElementById("screen-view");
      video.pause();
      video.currentTime = 0;
    },

    download(key) {
      if(key.links && key.links.length > 1){
        this.downloads = key.links;
        this.nameSelected = key.name;
        return this.showDownloadVideos = true;
      }
      const url = this.signObject(key);
      window.open(url, "_blank");
    },

    signObject(key) {
      const signedUrlExpireSeconds = 60 * 30; // your expiry time in seconds.
      return s3.getSignedUrl("getObject", {
        Bucket: bucketName,
        Key: key.name,
        Expires: signedUrlExpireSeconds,
      });
    },

    asesorName(item) {
      let name = this.advisors.filter((u) => u.id == item);
      name = name.length > 0 ? name[0].name : "No se encontró";
      return name;
    },
  },
};
</script>

<style lang="scss">
.active {
  background: #bdd6e8;
}
.VideoDK {
  position: relative;
  .button-dk {
    position: absolute;
    top: -20px;
    right: -20px;
  }
}
.ModalOverlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #30353880;
  z-index: 50;
}
.Modal {
  z-index: 100;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 900px;
  border-radius: 5px;
  animation: fade 0.5s forwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 960px) {
  .card-dk {
    width: 100%;
    min-width: 295px;
    margin: auto;
  }
}
</style>
